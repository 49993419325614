<template>
  <div class="idPoa">
    <idPoa v-if="isPageVisibleByIdPoa"></idPoa>
    <slot v-if="showPage"></slot>
  </div>
</template>

<script>
import idPoa from '@/components/idPoa/Index';
import idPoaMixin from '@/mixins/idPoa';

export default {
  name: 'IdPoaLimit',
  props: { showNotification: Boolean },
  components: { idPoa },
  mixins: [idPoaMixin],
  computed: {
    showPage() {
      return (
        (!this.requireIDProof &&
          !this.requireAddressProof &&
          !this.pendingIDProofApproval &&
          !this.pendingAddressProofApproval) ||
        !this.isPageVisibleByIdPoa
      );
    },
    isPageVisibleByIdPoa() {
      return this.$platform.isPageVisibleByIdPoa(this.regulator, this.$route.name);
    }
  }
};
</script>
