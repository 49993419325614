<template>
  <div class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget"></div>
    <div class="tradingview-widget-copyright">
      <a :href="`https://www.tradingview.com/symbols/${url}/technicals`" rel="noopener" target="_blank"
        ><span class="blue-text">{{ $t('tradingTools.techAna') }} {{ title }}</span></a
      >
      {{ $t('tradingTools.byTradingView') }}
    </div>
    <component
      :is="'script'"
      src="https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js"
      async
      >{{
        {
          interval: '5m',
          width: '100%',
          isTransparent: true,
          height: '100%',
          symbol: symbol,
          showIntervalTabs: true,
          locale: 'en',
          colorTheme: 'dark'
        }
      }}</component
    >
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    url: String,
    title: String,
    symbol: String
  }
};
</script>
