<template>
  <div id="marketWidgets">
    <div class="content_box">
      <div class="inner_max">
        <idPoaLimit>
          <h3>{{ $t('menu.technicalAnalysis') }}</h3>
          <div class="box pt-5 pb-5 pe-5 ps-5">
            <div class="tab mb-3 text-center">
              <div class="el-button mb-3" :class="{ active: active === 'forex' }" @click="active = 'forex'">
                {{ $t('menu.techAnalysisTab.forex') }}
              </div>
              <div class="el-button mb-3" :class="{ active: active === 'indices' }" @click="active = 'indices'">
                {{ $t('menu.techAnalysisTab.indices') }}
              </div>
              <div class="el-button mb-3" :class="{ active: active === 'Commodities' }" @click="active = 'Commodities'">
                {{ $t('menu.techAnalysisTab.commodities') }}
              </div>
              <div class="el-button mb-3" :class="{ active: active === 'ShareCFDs' }" @click="active = 'ShareCFDs'">
                {{ $t('menu.techAnalysisTab.sharedcfds') }}
              </div>
              <div class="el-button mb-3" :class="{ active: active === 'Crypto' }" @click="active = 'Crypto'">
                {{ $t('menu.techAnalysisTab.crypto') }}
              </div>
            </div>
            <div class="main">
              <template v-for="(option, index) in options[active]">
                <tradingViewWidget
                  v-if="renderComponent"
                  :key="index"
                  :name="`${active}${index}`"
                  :url="option.url"
                  :title="option.title"
                  :symbol="option.symbol"
                  class="container border border-dim-gray"
                ></tradingViewWidget>
              </template>
            </div>
          </div>
        </idPoaLimit>
      </div>
    </div>
  </div>
</template>

<script>
import idPoaMixin from '@/mixins/idPoa';
import idPoaLimit from '@/components/idPoa/Limit';
import tradingViewWidget from '@/components/TechnicalAnalysis/tradingViewWidget';

export default {
  name: 'technicalAnalysis',
  components: { tradingViewWidget, idPoaLimit },
  mixins: [idPoaMixin],
  data() {
    return {
      renderComponent: true,
      options: {
        forex: [
          {
            url: 'FX-EURCAD',
            title: 'EURCAD',
            symbol: 'FX:EURCAD'
          },
          {
            url: 'FX-NZDUSD',
            title: 'NZDUSD',
            symbol: 'FX:NZDUSD'
          },
          {
            url: 'FX-USDCAD',
            title: 'USDCAD',
            symbol: 'FX:USDCAD'
          },
          {
            url: 'FX-EURJPY',
            title: 'EURJPY',
            symbol: 'FX:EURJPY'
          },
          {
            url: 'FX-USDCHF',
            title: 'USDCHF',
            symbol: 'FX:USDCHF'
          },
          {
            url: 'FX-AUDUSD',
            title: 'AUDUSD',
            symbol: 'FX:AUDUSD'
          },
          {
            url: 'FX-GBPUSD',
            title: 'GBPUSD',
            symbol: 'FX:GBPUSD'
          },
          {
            url: 'FX-USDJPY',
            title: 'USDJPY',
            symbol: 'FX:USDJPY'
          },
          {
            url: 'FX-EURUSD',
            title: 'EURUSD',
            symbol: 'FX:EURUSD'
          }
        ],
        indices: [
          {
            url: 'NASDAQ-NDAQ',
            title: 'NDAQ',
            symbol: 'NASDAQ:NDAQ'
          },
          {
            url: 'TVC-DJI',
            title: 'DJI',
            symbol: 'TVC:DJI'
          },
          {
            url: 'TVC-NYA',
            title: 'NYA',
            symbol: 'TVC:NYA'
          },
          {
            url: 'CME-NKD1!',
            title: 'NKD1!',
            symbol: 'CME:NKD1!'
          },
          {
            url: 'EURONEXT-FTSE',
            title: 'FTSE',
            symbol: 'EURONEXT:FTSE'
          },
          {
            url: 'TVC-VIX',
            title: 'VIX',
            symbol: 'TVC:VIX'
          }
        ],
        Commodities: [
          {
            url: 'TVC-GOLD',
            title: 'GOLD',
            symbol: 'TVC:GOLD'
          },
          {
            url: 'COMEX-HG1!',
            title: 'HG1!',
            symbol: 'COMEX:HG1!'
          },
          {
            url: 'NYMEX-NG1!',
            title: 'NG1!',
            symbol: 'NYMEX:NG1!'
          },
          {
            url: 'ICEUS-CC1!',
            title: 'CC1!',
            symbol: 'ICEUS:CC1!'
          },
          {
            url: 'TVC-SILVER',
            title: 'SILVER',
            symbol: 'TVC:SILVER'
          },
          {
            url: 'OANDA-SUGARUSD',
            title: 'SUGARUSD',
            symbol: 'OANDA:SUGARUSD'
          },
          {
            url: 'NYMEX-CL1!',
            title: 'CL1!',
            symbol: 'NYMEX:CL1!'
          }
        ],
        Crypto: [
          {
            url: 'COINBASE-ETHUSD',
            title: 'ETHUSD',
            symbol: 'COINBASE:ETHUSD'
          },
          {
            url: 'COINBASE-LTCUSD',
            title: 'LTCUSD',
            symbol: 'COINBASE:LTCUSD'
          },
          {
            url: 'BITFINEX-DSHUSD',
            title: 'DSHUSD',
            symbol: 'BITFINEX:DSHUSD'
          },
          {
            url: 'COINBASE-BCHUSD',
            title: 'BCHUSD',
            symbol: 'COINBASE:BCHUSD'
          },
          {
            url: 'BITSTAMP-BTCUSD',
            title: 'BTCUSD',
            symbol: 'BITSTAMP:BTCUSD'
          }
        ],
        ShareCFDs: [
          {
            url: 'NYSE-BAC',
            title: 'BAC',
            symbol: 'NYSE:BAC'
          },
          {
            url: 'NASDAQ-INTC',
            title: 'INTC',
            symbol: 'NASDAQ:INTC'
          },
          {
            url: 'NYSE-C',
            title: 'C',
            symbol: 'NYSE:C'
          },
          {
            url: 'NYSE-BA',
            title: 'BA',
            symbol: 'NYSE:BA'
          },
          {
            url: 'NYSE-IBM',
            title: 'IBM',
            symbol: 'NYSE:IBM'
          },
          {
            url: 'NYSE-MCD',
            title: 'MCD',
            symbol: 'NYSE:MCD'
          },
          {
            url: 'NASDAQ-SBUX',
            title: 'SBUX',
            symbol: 'NASDAQ:SBUX'
          },
          {
            url: 'NYSE-DIS',
            title: 'DIS',
            symbol: 'NYSE:DIS'
          },
          {
            url: 'NYSE-V',
            title: 'V',
            symbol: 'NYSE:V'
          },
          {
            url: 'NYSE-KO',
            title: 'KO',
            symbol: 'NYSE:KO'
          },
          {
            url: 'NYSE-JPM',
            title: 'JPM',
            symbol: 'NYSE:JPM'
          },
          {
            url: 'NYSE-XOM',
            title: 'XOM',
            symbol: 'NYSE:XOM'
          },
          {
            url: 'NASDAQ-AMZN',
            title: 'AMZN',
            symbol: 'NASDAQ:AMZN'
          },
          {
            url: 'NASDAQ-MSFT',
            title: 'MSFT',
            symbol: 'NASDAQ:MSFT'
          },
          {
            url: 'NASDAQ-FB/',
            title: 'FB',
            symbol: 'NASDAQ:FB'
          },
          {
            url: 'NASDAQ-AAPL',
            title: 'AAPL',
            symbol: 'NASDAQ:AAPL'
          },
          {
            url: 'NASDAQ-NFLX',
            title: 'NFLX',
            symbol: 'NASDAQ:NFLX'
          },
          {
            url: 'NASDAQ-GOOGL',
            title: 'GOOGL',
            symbol: 'NASDAQ:GOOGL'
          }
        ]
      },
      active: 'indices'
    };
  },
  watch: {
    active() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/technicalAnalysis.scss';
</style>
